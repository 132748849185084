import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import * as style from "../../styles";
import bg from "../../assets/images/bg_photo.png";
import char1 from "../../assets/images/line_chart1.png";
import char2 from "../../assets/images/line_chart2.png";

const Dashboard = () => {
  const [randomNumber, setRandomNumber] = useState(0);

  useEffect(() => {
    const generateRandomNumber = () => {
      return Math.floor(Math.random() * 11) + 45;
    };

    const intervalId = setInterval(() => {
      setRandomNumber(generateRandomNumber());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      sx={{
        width: "100vw",
        p: "40px",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
      }}>
      <Box sx={{ width: "calc(100vw - 80px)" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}>
          <Typography variant="h2" sx={style.headerTextStyle}>
            溫室氣體盤查整合｜專案列表
          </Typography>
          <Typography>最新資料時間：2023/03/06 10:30</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "stretch",
          alignItems: "center",
          gap: "32px",
        }}>
        <Box
          sx={{
            width: "calc(50vw - 56px - 80px)",
            background:
              "linear-gradient(93.82deg, rgba(188, 0, 0, 0.4) 7.93%, rgba(255, 255, 255, 0) 95.6%), #FBBB0A;",
            minHeight: "50px",
            borderRadius: "15px",
            position: "relative",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            p: "40px",
          }}>
          <Box>
            <Typography
              sx={{
                color: "#ffffff",
                fontSize: "15px",
                lineHeight: "18px",
              }}>
              今日用電量
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}>
            <Typography
              sx={{
                color: "#ffffff",
                fontSize: "47px",
                lineHeight: "55px",
                fontWeight: 700,
              }}>
              {50 + 60 + 70 + 35 + randomNumber}
            </Typography>
            <Typography
              sx={{
                color: "#ffffff",
                fontSize: "15px",
                lineHeight: "18px",
                transform: "translateX(10px)",
              }}>
              kWH
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: "-2px",
              bottom: "-2px",
            }}>
            <img src={bg} alt="bg" />
          </Box>
        </Box>
        <Box
          sx={{
            width: "calc(50vw - 56px - 80px)",
            background:
              "linear-gradient(115.02deg, #016665 17.73%, #16E4B4 102.64%);",
            minHeight: "50px",
            borderRadius: "15px",
            position: "relative",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            p: "40px",
          }}>
          <Box>
            <Typography
              sx={{
                color: "#ffffff",
                fontSize: "15px",
                lineHeight: "18px",
              }}>
              今日天然氣流量合計(未開放本服務)
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}>
            <Typography
              sx={{
                color: "#ffffff",
                fontSize: "47px",
                lineHeight: "55px",
                fontWeight: 700,
              }}>
              --
            </Typography>
            <Typography
              sx={{
                color: "#ffffff",
                fontSize: "15px",
                lineHeight: "18px",
                transform: "translateX(10px)",
              }}>
              立方米
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: "-2px",
              bottom: "-2px",
            }}>
            <img src={bg} alt="bg" />
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "calc(100vw - 80px)", display: "flex", gap: "32px" }}>
        <Box
          sx={{
            background: "#ffffff",
            borderRadius: "8px",
            width: "calc(50vw - 56px - 80px)",
            p: "40px",
            gap: "30px",
            display: "flex",
            flexDirection: "column",
          }}>
          <Box
            sx={{
              display: "flex",
              gap: "30px",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                sx={{
                  letterSpacing: "0.15px",
                  fontSize: "12px",
                  lineHeight: "12px",
                  fontWeight: 400,
                }}>
                [ 電表編號 P0001a ]
              </Typography>
              <Typography
                sx={{
                  letterSpacing: "0.15px",
                  fontSize: "12px",
                  lineHeight: "12px",
                  fontWeight: 400,
                  ">span": {
                    color: "#FBBB0A",
                    fontSize: "30px",
                    lineHeight: "24px",
                    fontWeight: 700,
                  },
                }}>
                區間用電 <span>369.2</span> kWH
              </Typography>
              <Typography
                sx={{
                  letterSpacing: "0.15px",
                  fontSize: "12px",
                  lineHeight: "12px",
                  fontWeight: 400,
                  ">span": {
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 700,
                  },
                }}>
                電表度數 <span>3,562.26</span> kWH
              </Typography>
            </Box>
            <Box sx={{ width: "15vw" }}>
              <img src={char1} alt="chart1" style={{ width: "100%" }} />
            </Box>
          </Box>
          <Box sx={{ width: "100%", display: "flex", gap: "16px" }}>
            <TextField
              size="small"
              label="請選擇區間－起"
              focused
              value={"2023/03/01 00:00:00"}
              fullWidth
            />
            <TextField
              size="small"
              label="請選擇區間－迄"
              focused
              value={"2023/03/01 00:00:00"}
              fullWidth
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Button
              sx={(theme) => ({
                ...style.textButtonStyle,
                color: theme.palette.error.main,
              })}>
              關閉區間分析
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            background: "#ffffff",
            borderRadius: "8px",
            width: "calc(50vw - 56px - 80px)",
            p: "40px",
            gap: "30px",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backdropFilter: "blur(8px)",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
              borderRadius: "8px",
            }}>
            <Typography
              sx={{
                color: "#000000",
                fontSize: "24px",
                fontWeight: 600,
              }}>
              未開放本服務
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "30px",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                sx={{
                  letterSpacing: "0.15px",
                  fontSize: "12px",
                  lineHeight: "12px",
                  fontWeight: 400,
                }}>
                [ 電表編號 P0001a ]
              </Typography>
              <Typography
                sx={{
                  letterSpacing: "0.15px",
                  fontSize: "12px",
                  lineHeight: "12px",
                  fontWeight: 400,
                  ">span": {
                    color: "#00B388",
                    fontSize: "30px",
                    lineHeight: "24px",
                    fontWeight: 700,
                  },
                }}>
                區間用電 <span>369.2</span> kWH
              </Typography>
              <Typography
                sx={{
                  letterSpacing: "0.15px",
                  fontSize: "12px",
                  lineHeight: "12px",
                  fontWeight: 400,
                  ">span": {
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 700,
                  },
                }}>
                電表度數 <span>3,562.26</span> kWH
              </Typography>
            </Box>
            <Box sx={{ width: "15vw" }}>
              <img src={char2} alt="chart2" style={{ width: "100%" }} />
            </Box>
          </Box>
          <Box sx={{ width: "100%", display: "flex", gap: "16px" }}>
            <TextField
              size="small"
              label="請選擇區間－起"
              focused
              value={"2023/03/01 00:00:00"}
              fullWidth
            />
            <TextField
              size="small"
              label="請選擇區間－迄"
              focused
              value={"2023/03/01 00:00:00"}
              fullWidth
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Button
              sx={(theme) => ({
                ...style.textButtonStyle,
                color: theme.palette.error.main,
              })}>
              關閉區間分析
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "calc(100vw - 80px)",
          display: "grid",
          gap: "32px",
          gridTemplateColumns: "repeat(4, 1fr)",
        }}>
        {[
          {
            a: 50,
            c: "#FBBB0A",
          },
          {
            a: 60,
            c: "#FBBB0A",
          },
          {
            a: 70,
            c: "#00B388",
          },
          {
            a: 45,
            c: "#00B388",
          },
          {
            a: 45,
            c: "#00B388",
          },
        ].map((_, index) => (
          <InfoBox
            key={index}
            a={_.a}
            index={(index + 1).toString()}
            color={_.c}
          />
        ))}
      </Box>
    </Box>
  );
};

const InfoBox = (props: { color: string; a: number; index: string }) => {
  const { color, index, a } = props;
  const [randomNumber, setRandomNumber] = useState(0);
  const [day] = useState(new Date().getDate());

  useEffect(() => {
    const generateRandomNumber = () => {
      return Math.floor(Math.random() * 11) + a;
    };

    const generateRandomInterval = () => {
      return Math.floor(Math.random() * 4001) + 1000;
    };

    const updateNumber = () => {
      setRandomNumber(generateRandomNumber());
      const newInterval = generateRandomInterval();
      setTimeout(updateNumber, newInterval); // Use setTimeout instead of setInterval
    };

    updateNumber();
  }, [a]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        justifyContent: "center",
        alignItems: "center",
        background: "#ffffff",
        padding: "10px 40px",
        borderRadius: "8px",
        width: "calc(25vw - 56px - 80px)",
      }}>
      <Typography
        sx={{
          letterSpacing: "0.15px",
          fontSize: "12px",
          lineHeight: "12px",
          fontWeight: 400,
        }}>
        [ 電表編號 P000{index} ]
      </Typography>
      <Typography
        sx={{
          letterSpacing: "0.15px",
          fontSize: "12px",
          lineHeight: "12px",
          fontWeight: 400,
          ">span": {
            color: color,
            fontSize: "30px",
            lineHeight: "24px",
            fontWeight: 700,
          },
        }}>
        區間用電 <span>{randomNumber}</span> kWH
      </Typography>
      <Typography
        sx={{
          letterSpacing: "0.15px",
          fontSize: "12px",
          lineHeight: "12px",
          fontWeight: 400,
          ">span": {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 700,
          },
        }}>
        電表度數 <span>{day * randomNumber}</span> kWH
      </Typography>
      <Box>
        <Button
          sx={(theme) => ({
            ...style.textButtonStyle,
            color: theme.palette.primary.main,
          })}>
          開啟區間分析
        </Button>
      </Box>
    </Box>
  );
};

export default Dashboard;
